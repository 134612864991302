import {firestoreAction} from "vuexfire";
import moment from "moment";
import {db} from '@/firebase'

const state = {
    repairKit: null,
    repairKitForFsl: null,
    repairKits: [],
    availablePickingDesks: [],
    toBePicked: 'TO_BE_PICKED',
    picked: 'PICKED',
    packed: 'PACKED',
    shipped: 'SHIPPED',
    technicianReceived: 'TECHNICIAN_RECEIVED',
    toBeReturned: 'TO_BE_RETURNED',
    shippedBack: 'SHIPPED_BACK',
    kitShippedBack: 'SHIPPED_BACK',
    repairKitDetails: [],
    shipmentIdBeingPacked: null,
    shipmentIdBeingReceived: null,
    shipmentIdBeingReturned: null,
};

const getters = {
    repairKit: state => state.repairKit,
    repairKitForFsl: state => state.repairKitForFsl,
    repairKits: state => state.repairKits,
    pickingDesks: state => state.availablePickingDesks,
    repairKitsToBePicked: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.toBePicked),
    repairKitsPicked: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.picked),
    repairKitsPacked: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.packed),
    repairKitsShipped: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.shipped),
    repairKitsTechnicianReceived: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.technicianReceived),
    repairKitsToBeReturned: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.toBeReturned),
    repairKitsShippedBack: state => state.repairKits.filter(repairKit => repairKit.state_constant === state.kitShippedBack),

    repairKitDetails: state => state.repairKitDetails,
    detailsPicked: (state) => {
        return state.repairKitDetails.filter(detail => (detail.stock_serial_number_id || detail.picked));
    },
    detailsToBePicked: (state) => {
        return state.repairKitDetails.filter(detail => (!detail.stock_serial_number_id && !detail.picked));
    },

    shipmentIdBeingPacked: state => state.shipmentIdBeingPacked,
    shipmentIdBeingReceived: state => state.shipmentIdBeingReceived,
    shipmentIdBeingReturned: state => state.shipmentIdBeingReturned
};

const actions = {

    /** Load today's repair kits */
    loadTodaysRepairKits: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            let date = moment().format('YYYY-MM-DD');
            // date = "2019-11-27";

            bindFirestoreRef(
                'repairKits',
                db.collection('repair_kits').where('dates_viewable', 'array-contains', date)
            ).then(() => {
                resolve();
            }).catch(e => {
                reject(e);
            })
        });
    }),

    /** Load a repair kit */
    loadRepairKit: firestoreAction(({bindFirestoreRef}, {repairKitId}) => {
        return new Promise((resolve, reject) => {
            repairKitId = repairKitId.toString();

            bindFirestoreRef(
                'repairKit',
                db.collection('repair_kits').doc(repairKitId)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load a repair kit for checking if it's FSL or not */
    loadRepairKitForFsl: firestoreAction(({bindFirestoreRef}, {repairKitId}) => {
        return new Promise((resolve, reject) => {
            repairKitId = repairKitId.toString();

            bindFirestoreRef(
                'repairKitForFsl',
                db.collection('repair_kits').doc(repairKitId)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load a repair kit's details */
    loadRepairKitDetails: firestoreAction(({bindFirestoreRef}, {repairKitId}) => {
        return new Promise((resolve, reject) => {
            repairKitId = repairKitId.toString();

            bindFirestoreRef(
                'repairKitDetails',
                db.collection(`repair_kits/${repairKitId}/repair_kit_details`).orderBy('stock_item_title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Print repair kit label */
    printRepairKitLabel({commit}, {repairKitId, deskId}) {

        db.collection('repair_kits')
            .doc(repairKitId)
            .update({ label_printed: true })
            .then(() => {
                commit('PRINT_LABEL');
            });

        this.$axios.post(`/api/v4/repair-kits/${repairKitId}/print-label/${deskId}`, {
            deskId: deskId
        }).then(() => {
            this.printing = true;
        }).catch(errors => {
            this.displayToast({text: errors.response.data.messages, type: 'error', duration: 6000});
        });

    },

    checkStockItemLocation(__, {stockSerialNumberId, repairKitId}) {
        return new Promise((resolve) => {
            stockSerialNumberId = stockSerialNumberId.toString();

            let stockLocationRef = db.collection(`stock_current_locations`).doc(stockSerialNumberId);

            stockLocationRef.get()
                .then((snapshot) => {
                    if (!snapshot.exists) {
                        return resolve();
                    }
                    let data = snapshot.data();

                    if (
                        data.current_location_model === "App\\Models\\RepairKit" &&
                        data.current_location_id.toString() === repairKitId.toString()
                    ) {
                        return resolve();
                    }
                    // if (!data.can_be_added_to_repair_kit) {
                    //     return reject("This stock item has been used. Hand to Returns.");
                    // }

                    return resolve();
                });
        });
    },

    repairKitDetailBarcodeScanned({dispatch}, {barcodeScanned, repairKitId}) {
        return new Promise((resolve, reject) => {
            if (barcodeScanned.type === 'serialNumber') {

                // We need to check that this part's current location is the warehouse
                return dispatch('checkStockItemLocation', {stockSerialNumberId: barcodeScanned.id, repairKitId: repairKitId})
                    .then(() => {
                        return dispatch('addSerialNumber', barcodeScanned)
                            .then(message => {
                                return resolve(message);
                            })
                            .catch(message => {
                                return reject(message);
                            });

                    })
                    .catch(message => {
                        return reject(message);
                    });
            }

            if (barcodeScanned.type === 'asset') {
                return dispatch('addAsset', barcodeScanned)
                    .then(message => {
                        return resolve(message);
                    })
                    .catch(message => {
                        return reject(message);
                    });
            }
            return reject('Unknown barcode type.');
        });
    },

    /** Add serial number */
    addSerialNumber({getters}, barcodeScanned) {
        return new Promise((resolve, reject) => {

            let stockItemRequired = false;

            getters.repairKitDetails.forEach(detail => {
                if (barcodeScanned.stock_id === detail.stock_item_id) {
                    stockItemRequired = true;
                }
            });

            if (!stockItemRequired) {
                reject("This stock item is not part of this kit");
                return;
            }

            let hasErrors = false;

            getters.detailsPicked.forEach(detail => {
                if (barcodeScanned.id === detail.stock_serial_number_id) {
                    reject("Serial number already scanned");
                    hasErrors = true;
                }
            });

            if (hasErrors) {
                return;
            }

            let serialNumberAdded = false;

            getters.detailsToBePicked.forEach(detail => {
                if (serialNumberAdded) {
                    return;
                }

                if (barcodeScanned.stock_id === detail.stock_item_id) {

                    serialNumberAdded = true;

                    db.collection(`repair_kits/${detail.repair_kit_id}/repair_kit_details`)
                        .doc(detail.id)
                        .update({ stock_serial_number_id: barcodeScanned.id })
                        .then(() => {
                            resolve("Serial number added");
                        });
                }
            });

        });

    },

    /** Add serial number */
    addAsset({getters}, barcodeScanned) {
        return new Promise((resolve, reject) => {

            let assetRequired = false;

            getters.repairKitDetails.forEach(detail => {
                if (barcodeScanned.id === detail.asset_id) {
                    assetRequired = true;
                }
            });

            if (!assetRequired) {
                reject("This asset is not part of this kit");
                return;
            }

            let hasErrors = false;

            getters.detailsPicked.forEach(detail => {
                if (barcodeScanned.id === detail.asset_id) {
                    reject("Asset already scanned");
                    hasErrors = true;
                }
            });

            if (hasErrors) {
                return;
            }

            let assetAdded = false;

            getters.detailsToBePicked.forEach(detail => {
                if (assetAdded) {
                    return;
                }

                if (barcodeScanned.id === detail.asset_id) {

                    assetAdded = true;

                    db.collection(`repair_kits/${detail.repair_kit_id}/repair_kit_details`)
                        .doc(detail.id)
                        .update({ picked: true })
                        .then(() => {
                            resolve("Asset added");
                        });
                }
            });
        });
    },

    /** Set the scanned shipment */
    setShipmentScanned({commit}, shipmentId) {
        commit('SET_SHIPMENT_SCANNED', shipmentId);
    },

    /** Clear the scanned shipment */
    clearShipmentScanned({commit}) {
        commit('CLEAR_SHIPMENT_SCANNED');
    },

    /** Load shipment details */
    loadShipmentDetails: firestoreAction(({bindFirestoreRef}, {barcodeScanned}) => {
        return new Promise((resolve, reject) => {
            if (barcodeScanned.type !== 'shipment') {
                return resolve();
            }

            bindFirestoreRef(
                'repairKits',
                db.collection(`repair_kits`)
                    .where('shipment_id', '==', barcodeScanned.id)
                    .orderBy('day_number')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Set the scanned shipment */
    setReceivedShipmentScanned({commit}, shipmentId) {
        commit('SET_RECEIVED_SHIPMENT_SCANNED', shipmentId);
    },

    /** Clear the scanned shipment */
    clearReceivedShipmentScanned({commit}) {
        commit('CLEAR_RECEIVED_SHIPMENT_SCANNED');
    },

    /** Load inbound shipment details */
    loadReceivedShipmentDetails: firestoreAction(({bindFirestoreRef}, {barcodeScanned}) => {
        return new Promise((resolve, reject) => {
            if (barcodeScanned.type !== 'shipment') {
                return resolve();
            }

            bindFirestoreRef(
                'repairKits',
                db.collection(`repair_kits`)
                    .where('shipment_id', '==', barcodeScanned.id)
                    .where('state_constant', 'in', [state.packed, state.technicianReceived])
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Set the scanned return shipment */
    setReturnShipmentScanned({commit}, shipmentId) {
        return new Promise((resolve) => {
            commit('SET_RETURN_SHIPMENT_SCANNED', shipmentId);
            resolve();
        });
    },

    /** Clear the scanned return shipment */
    clearReturnShipmentScanned({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_RETURN_SHIPMENT_SCANNED');
            resolve();
        });
    },

    /** Load outbound shipment details */
    loadReturnShipmentDetails: firestoreAction(({bindFirestoreRef}, {barcodeScanned}) => {
        return new Promise((resolve, reject) => {
            if (barcodeScanned.type !== 'returnShipment') {
                return resolve();
            }

            bindFirestoreRef(
                'repairKits',
                db.collection(`repair_kits`)
                    .where('return_shipment_id', '==', barcodeScanned.id)
                    .where('state_constant', '==', state.kitShippedBack)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Change repair kit status to be picked */
    setRepairKitAsPicked({getters, state}, repairKitId) {
        repairKitId = repairKitId.toString();
        let userId = (getters.user && getters.user.id) ? getters.user.id : null;

        db.collection('repair_kits')
            .doc(repairKitId)
            .update({
                state_constant: state.picked,
                update_by_user: userId
            });
    },

    setRepairKitAsPickedDirectly ({ getters, state }, repairKitId) {
        return new Promise((resolve) => {
            let userId = (getters.user && getters.user.id) ? getters.user.id : null;

            this.$axios
                .put(`/api/v4/repair-kits/${repairKitId}/state`, {
                    state_constant: state.picked,
                    updated_by_user: userId,
                    source: 'admin-app',
                })
                .then(() => {
                    resolve();
                })
        });
    },

    /** Change repair kit status as packed */
    setRepairKitAsPacked({getters, state}, repairKitId) {
        repairKitId = repairKitId.toString();
        let userId = (getters.user && getters.user.id) ? getters.user.id : null;

        db.collection('repair_kits')
            .doc(repairKitId)
            .update({
                state_constant: state.packed,
                update_by_user: userId
            });
    },

    /** Change repair kit status technician received  */
    setRepairKitAsTechnicianReceived({getters, state}, repairKitId) {
        return new Promise((resolve, reject) => {
            repairKitId = repairKitId.toString();
            let userId = (getters.user && getters.user.id) ? getters.user.id : null;

            db.collection('repair_kits')
                .doc(repairKitId)
                .update({
                    state_constant: state.technicianReceived,
                    update_by_user: userId
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Change repair kit status technician received for FSL kits */
    setRepairKitAsTechnicianReceivedDirectly ({ getters, state }, repairKitId) {
        return new Promise((resolve, reject) => {
            let userId = (getters.user && getters.user.id) ? getters.user.id : null;

            this.$axios
                .put(`/api/v4/repair-kits/${repairKitId}/state`, {
                    state_constant: state.technicianReceived,
                    updated_by_user: userId,
                    source: 'admin-app',
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Change repair kit status shipped back  */
    setRepairKitAsShippedBack({getters, state}, repairKitId) {
        return new Promise((resolve, reject) => {
            repairKitId = repairKitId.toString();
            let userId = (getters.user && getters.user.id) ? getters.user.id : null;

            db.collection('repair_kits')
                .doc(repairKitId)
                .update({
                    state_constant: state.kitShippedBack,
                    return_shipment_id: state.shipmentIdBeingReturned,
                    update_by_user: userId
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    setRepairKitAsShippedBackDirectly ({ getters, state }, repairKitId) {
        return new Promise((resolve) => {
            let userId = (getters.user && getters.user.id) ? getters.user.id : null;

            this.$axios
                .put(`/api/v4/repair-kits/${repairKitId}/state`, {
                    state_constant: state.kitShippedBack,
                    updated_by_user: userId,
                    source: 'admin-app',
                })
                .then(() => {
                    resolve();
                })
        });
    },

    getPickingDesks ({ commit }) {
        return new Promise((resolve) => {
            this.$axios
                .get(`/api/v4/repair-kits/picking-desks`)
                .then(response => response.data.data)
                .then(pickingDesks => {
                    commit('SET_PICKING_DESKS', pickingDesks)
                    resolve();
                })
        });
    },

    /** Sync the kit list */
    syncKitList() {
        this.$axios.get(`/api/v4/repair-kits/sync`);
    }

};

const mutations = {

    SET_PICKING_DESKS(state, pickingDesks) {
      state.availablePickingDesks = pickingDesks;
    },

    SET_SHIPMENT_SCANNED(state, shipmentId) {
        state.shipmentIdBeingPacked = shipmentId;
    },

    CLEAR_SHIPMENT_SCANNED(state) {
        state.shipmentIdBeingPacked = null;
    },

    SET_RECEIVED_SHIPMENT_SCANNED(state, shipmentId) {
        state.shipmentIdBeingReceived = shipmentId;
    },

    CLEAR_RECEIVED_SHIPMENT_SCANNED(state) {
        state.shipmentIdBeingReceived = null;
    },

    SET_RETURN_SHIPMENT_SCANNED(state, shipmentId) {
        state.shipmentIdBeingReturned = shipmentId;
    },

    CLEAR_RETURN_SHIPMENT_SCANNED(state) {
        state.shipmentIdBeingReturned = null;
    },

    PRINT_LABEL(state) {
        state.repairKit.label_printed = true;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}